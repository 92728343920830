import { Link } from 'react-router-dom';

function Corsi() {
  return (
        <section className="pt-12" id="empty">
            <section className="pt-7" id="corsi">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h5 className="text-secondary">Corsi e test</h5>
                            <h2 className="mb-2 fs-7 fw-bold">Scopri tutti i corsi e test di profacile.it</h2>
                            <p className="mb-4 fw-medium text-secondary">
                            Iscriviti e inizia subito seguire corsi e test per diventare un professionista e migliorare le tue skill.
                            </p>
                            <h4 className="fs-1 fw-bold">Utilizza la nostra piattaforma di simulazione d'esame</h4>
                            <p className="mb-4 fw-medium text-secondary">La nostra piattaforma di simulazione d'esame ti aiuterà a prepararti al giorno dell'esame e a certificarti
                            </p>                        
                            <h4 className="fs-1 fw-bold">Più opportunità di lavoro</h4>
                            <p className="mb-4 fw-medium text-secondary">La formazione e le certificazioni aumenteranno le tue opportunità lavorative</p>
                            <p>
                            <Link to="/test-aws-cp" className="btn btn-primary fw-medium py-1">Scopri di più</Link>
                            </p>
                        </div>
                        <div className="col-lg-6"><img className="img-fluid" src="assets/img/validation/validation.png" alt="" /></div>
                    </div>
              </div>  
            </section>
        </section>
    );
}

export default Corsi;
